"use client";

import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItemButton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { CiTwitter, CiFacebook, CiInstagram, CiLinkedin } from "react-icons/ci";
import { ImPinterest2 } from "react-icons/im";
import { useAgency } from "../../hooks/use-agency";
import { useMenuLinks } from "../../hooks/use-menu-links";
import { Trans, useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { useCallback, useState } from "react";
import dynamic from "next/dynamic";
const Lottie = dynamic(() => import("lottie-react"), { ssr: false });
import sendedOk from "../../lotties/91068-message-sent-successfully-plane.json";
import { GrStatusWarning } from "react-icons/gr";
import { getSectionLink } from "../../utils";

export default function Footer() {
  const { t, i18n } = useTranslation();
  const agency = useAgency();
  const links = useMenuLinks();

  if (!agency) return null;

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.common.white,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        mt: 6,
        pt: 4,
        pb: 2,
      }}
    >
      <Container>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={12} md={4.5}>
            <Typography variant="h5" gutterBottom>
              {agency?.name}
            </Typography>
            <Typography gutterBottom>
              Somos una inmobiliaria que se preocupa por sus clientes.
              Trabajamos con pasión, energía, seriedad y transparencia. Nuestro
              recorrido nos avala, ¡confía en nosotros!
            </Typography>
            <Typography gutterBottom>
              © {new Date().getFullYear()} - {agency?.name}
            </Typography>
            <Typography gutterBottom>
              {t("data_protection.copyright")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 1,
                mt: 2,
              }}
            >
              <Link
                href={getSectionLink(agency, "privacy-policy", i18n.language)}
              >
                {t("menu.privacy_policy")}
              </Link>
              <Link
                href={getSectionLink(agency, "legal-notice", i18n.language)}
              >
                {t("menu.legal_warn")}
              </Link>
              {/* <Link href="/es/politica-de-cookies">
                {t("menu.cookies_policy")}
              </Link> */}
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 0.5,
                  textAlign: "center",
                }}
              >
                {links.map((x) => (
                  <Link key={`m3_${x.name}`} href={x.link}>
                    {x.name}
                  </Link>
                ))}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 0.5,
                  mt: 0,
                  justifyContent: "center",
                }}
              >
                {agency.social_medias?.twitter && (
                  <IconButton href={agency.social_medias.twitter}>
                    <CiTwitter />
                  </IconButton>
                )}
                {agency.social_medias?.facebook && (
                  <IconButton href={agency.social_medias.facebook}>
                    <CiFacebook />
                  </IconButton>
                )}
                {agency.social_medias?.instagram && (
                  <IconButton href={agency.social_medias.instagram}>
                    <CiInstagram />
                  </IconButton>
                )}
                {agency.social_medias?.linkedin && (
                  <IconButton href={agency.social_medias.linkedin}>
                    <CiLinkedin />
                  </IconButton>
                )}
                {agency.social_medias?.pinterest && (
                  <IconButton href={agency.social_medias.pinterest}>
                    <ImPinterest2 />
                  </IconButton>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4.5}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <NewsletterSubscription />
              <Box
                component={Link}
                href="https://serviestate.com"
                target="_blank"
                sx={{
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                  textDecoration: "none",
                  color: "inherit",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                <Box>
                  <LogoServiestate src="/assets/logo-tandem.png" />
                </Box>
                <Box>
                  <Typography variant="h6">{t("power_by.title")}</Typography>
                  <Typography>{t("power_by.claim")}</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

function NewsletterSubscription() {
  const { t, i18n } = useTranslation();

  const [sendStatus, setSendStatus] = useState<
    "sending" | "sended" | "error" | null
  >(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      accept_communications: false,
    },
  });

  const handleSub = useCallback(
    async (form: any) => {
      setSendStatus("sending");

      try {
        const response = await fetch("/api/subscribe-newsletter", {
          method: "POST",
          body: JSON.stringify({ ...form, language_code: i18n.language }),
          headers: { "content-type": "application/json" },
        });
        setSendStatus(response.ok ? "sended" : "error");
      } catch (e) {
        setSendStatus("error");
      }
    },
    [i18n]
  );

  return (
    <form onSubmit={handleSubmit(handleSub)}>
      <Typography variant="h5">{t("newsletter.title")}</Typography>
      {sendStatus === "sended" && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ maxWidth: 100 }}>
            <Lottie animationData={sendedOk} loop={false} />
          </Box>
        </Box>
      )}
      {sendStatus !== "sended" && (
        <Box>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <TextField
              {...register("email", {
                required: t("validations.email.required") as string,
              })}
              disabled={sendStatus === "sending"}
              required
              size="small"
              type="email"
              error={!!errors.email}
              helperText={errors.email?.message}
              fullWidth
              label={t("contact.email")}
            />
            <Button type="submit" variant="contained">
              {t("newsletter.subscribe")}
            </Button>
          </Box>

          <FormControlLabel
            disabled={sendStatus === "sending"}
            label={
              <Trans i18nKey="data_protection.check_accept_communications" />
            }
            control={
              <Checkbox size="small" {...register("accept_communications")} />
            }
          />
          {sendStatus === "sending" && <LinearProgress />}
          {sendStatus === "error" && (
            <Alert icon={<GrStatusWarning color="#fff" />} color="error">
              {t("contact.error")}
            </Alert>
          )}
        </Box>
      )}
    </form>
  );
}

const LogoServiestate = styled("img")({ width: 60, height: 60 });
